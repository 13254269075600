@import "guidlineColor";
@import "guidline";
@import "guidlineMedia";

.processing_section {
    width: 100%;
    background-image: url("https://d1kaer0po85hkk.cloudfront.net/assets/pagenotfound_bg.webp");
    background-image: -webkit-image-set(url("https://d1kaer0po85hkk.cloudfront.net/assets/pagenotfound_bg.png.webp"));
    background-image: image-set(url("https://d1kaer0po85hkk.cloudfront.net/assets/pagenotfound_bg.png"));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    @include tab_view {
        height: auto;
    }

    .processing_cover {
        display: block;
        width: 100%;
        padding: 40px 0;

        .block_center {
            max-width: 560px;
            width: 100%;
            margin: 0 auto;

            .processing_img {
                text-align: center;

                img {
                    height: 100%;
                    width: 100%;
                    max-width: 400px;
                    object-fit: contain;
                }
            }

            .processing_contant {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-flow: column;
                padding: 50px 0 0;

                h4 {
                    width: 100%;
                    @include heading_2;
                    @include font_700;
                    color: color(black);
                    margin-bottom: 12px;
                    text-align: center;
                }

                span {
                    @include small_text;
                    @include font_400;
                    color: color(dark_gray);
                    width: 100%;
                    max-width: 394px;
                    text-align: center;
                }

                .page_btn {
                    width: 100%;
                    display: inline-flex;
                    justify-content: center;

                    a {
                        padding: 22px 64px;
                        border-radius: 82px;
                        margin: 20px 0;
                        background: color(Contact_btn);
                        border: none;
                        color: color(whit_grid);
                        @include medium_text;
                        @include font_400;
                    }
                }
            }
        }
    }
}

.page_404_main {
    background-color: red;

    header {
        background-color: color(Contact_btn) !important;
    }
}

.errorPage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100vh;
    width: 100%;
    background: transparent url("https://d1kaer0po85hkk.cloudfront.net/assets/pagenotfound_bg.webp") no-repeat center center/cover;
    background: transparent -webkit-image-set(url("https://d1kaer0po85hkk.cloudfront.net/assets/pagenotfound_bg.png.webp")) no-repeat center center/cover;
    background: transparent image-set(url("https://d1kaer0po85hkk.cloudfront.net/assets/pagenotfound_bg.png")) no-repeat center center/cover;

    .imgArea {
        @include tab-view {
            max-width: 500px;
            padding: 0 20px;
            width: 100%;
        }
    }

    .infoArea {
        text-align: center;
        margin-top: 4.8rem;

        .heading {
            color: #111111;
            font-feature-settings: 'liga'off;
            line-height: 1.5;
            @include font-28;
            @include font-700;
        }

        .descriptionArea {
            color: #858c94;
            margin-bottom: 2.8rem;
            line-height: 1.5;
            @include font-20;
            @include font-400;
        }
    }
}